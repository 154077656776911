import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="USA" />
    <h1>This is the USA page</h1>
    <p>Are you in the USA?</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default SecondPage
